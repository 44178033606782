<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /**
     * The label to display for the checkbox.
     */
    label?: string

    /**
     * The value to set when the checkbox is checked.
     */
    trueValue?: any

    /**
     * The value to set when the checkbox is unchecked.
     */
    falseValue?: any

    /**
     * The (non-model) value of the checkbox.
     */
    value?: any

    /**
     * The model value of the checkbox.
     */
    modelValue?: any

    /**
     * Don't emit
     */
    dontEmitValue?: boolean

    /**
     * The form input identifier.
     */
    id?: string

    /**
     * An error message to display below the checkbox label.
     */
    error?: string | boolean

    /**
     * Whether the checkbox is disabled.
     */
    disabled?: boolean

    /**
     * Whether the checkbox is in indeterminate state.
     */
    indeterminate?: boolean

    /**
     * The shape of the checkbox.
     */
    shape?: 'straight' | 'rounded' | 'smooth' | 'curved' | 'full'

    /** The color of the checkbox. Can be 'default', 'primary', 'info', 'success', 'warning', or 'danger' */
    color?:
      | 'default'
      | 'light'
      | 'muted'
      | 'primary'
      | 'info'
      | 'success'
      | 'warning'
      | 'danger'

    /**
     * Optional CSS classes to apply to the wrapper, label, and input elements.
     */
    classes?: {
      /**
       * CSS classes to apply to the wrapper element.
       */
      wrapper?: string | string[]

      /**
       * CSS classes to apply to the label element.
       */
      label?: string | string[]

      /**
       * CSS classes to apply to the input element.
       */
      input?: string | string[]
    }
  }>(),
  {
    value: undefined,
    modelValue: undefined,
    id: undefined,
    label: undefined,
    error: '',
    trueValue: true,
    falseValue: false,
    shape: undefined,
    color: undefined,
    classes: () => ({}),
  },
)

const emits = defineEmits(['update:modelValue', 'change', 'click'])

// NOTE: keep in mind that for multi-checkboxes, the `value` prop is the value of each item,
// for exmaple, the ID of a category.
// Don't confuse this prop.value with the v-model value!

/**
 * Fucking weird and messed up stuff with checkboxes.
 * For single checkboxes:
 *  - Don't set type="checkbox" on the Field component.
 * For multi checkboxes:
 *  - Do set type=checkbox
 *  - Pass :dont-emit-value="true" to the BaseCheckbox
 *  - Set a :value on the Field for each unique value (e.g. the id)
 *
 * For examples, check the Time.vue component. It has both cases.
 */

defineOptions({
  inheritAttrs: false,
})

const appConfig = useAppConfig()
const shape = computed(() => props.shape ?? appConfig.nui.defaultShapes?.input)

const inputRef = ref<HTMLInputElement>()

// Make sure to initialize the value with either the modelValue or value prop.
// v-bind="field" sets the `value` prop, while v-model sets the `modelValue` prop.
const valuePropKey = props.modelValue !== undefined ? 'modelValue' : 'value'
// Use toRef to make sure we dont use (<- typo? "lose") reactivity, making sure that prop changes will be reflected.)
const valueProp = toRef(props, valuePropKey)
const valueRef = ref<any>(valueProp.value)
// Sync the prop with the ref
watch(valueProp, v => (valueRef.value = v))

// So... when there's a @change or @input listener, we assume v-bind="field" is being used.
// and then we use update:modelValue instead of v-model.
const isUsingVbind = !!useAttrs().onChange || !!useAttrs().onInput

watch(valueRef, (val, oldVal) => {
  // console.log(`Watch value => `, val, oldVal)

  // I don't know why, but for multi-checkboxes, emiting either a change or update:modelValue
  // prevents it from working properly. Need to dig deeper to figure out why.
  // I guess the v-bind already takes care of it?
  if (props.dontEmitValue) {
    console.log(`Not emitting value`)
    return
  }

  // When onChange or onInput, we're asuming v-bind="field" is used, so we don't
  // want to clash with v-model.
  if (isUsingVbind) {
    console.log(`Emit change => `, val)
    emits('change', val)
  }
  else {
    console.log(`Emit update:modelValue => `, val)
    emits('update:modelValue', val)
  }
})

const shapeStyle = {
  straight: '',
  rounded: 'nui-checkbox-rounded',
  smooth: 'nui-checkbox-smooth',
  curved: 'nui-checkbox-curved',
  full: 'nui-checkbox-full',
}
const colorStyle = {
  default: 'nui-checkbox-default',
  light: 'nui-checkbox-light',
  muted: 'nui-checkbox-muted',
  primary: 'nui-checkbox-primary',
  info: 'nui-checkbox-info',
  success: 'nui-checkbox-success',
  warning: 'nui-checkbox-warning',
  danger: 'nui-checkbox-danger',
}

watchEffect(() => {
  if (inputRef.value) {
    inputRef.value.indeterminate = props.indeterminate ?? false
  }
})

defineExpose({
  /**
   * The underlying HTMLInputElement element.
   */
  el: inputRef,
})

const id = useNinjaId(() => props.id)
</script>

<template>
  <div
    class="nui-checkbox"
    :class="[
      props.disabled && 'opacity-50',
      shape && shapeStyle[shape],
      props.color && colorStyle[props.color],
      props.classes?.wrapper,
    ]"
  >
    <div class="nui-checkbox-outer">
      
      
      <input
        v-if="dontEmitValue"
        v-bind="$attrs"
        :id="id"
        ref="inputRef"
        :value="props.value"
        :true-value="props.trueValue"
        :false-value="props.falseValue"
        :class="props.classes?.input"
        :disabled="props.disabled"
        class="nui-checkbox-input"
        type="checkbox"
        @click="emits('click')"
      >
      
      <input
        v-else
        :id="id"
        ref="inputRef"
        v-model="valueRef"
        :value="props.value"
        :true-value="props.trueValue"
        :false-value="props.falseValue"
        :class="props.classes?.input"
        :disabled="props.disabled"
        class="nui-checkbox-input"
        type="checkbox"
      >
      <div class="nui-checkbox-inner" />
      <IconCheck class="nui-icon-check" />
      <IconIndeterminate class="nui-icon-indeterminate" />
    </div>
    <div class="nui-checkbox-label-wrapper">
      
      <label
        v-if="props.label || 'default' in $slots"
        :for="id"
        class="nui-checkbox-label-text"
        :class="props.classes?.label"
      >
        <slot>{{ props.label }}</slot>
      </label>
      <div
        v-if="props.error && typeof props.error === 'string'"
        class="nui-checkbox-error"
      >
        {{ props.error }}
      </div>
    </div>
  </div>
</template>
